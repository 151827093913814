






import { Observer } from 'mobx-vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { PoolState, formatDuration } from '../business/swap-contract.business'

@Observer
@Component({ components: {} })
export default class IdoPoolState extends Vue {
  @Prop() state?: PoolState
  @Prop() showOnlyState?: boolean
  text = ''
  warn = false

  @Watch('state', { immediate: true }) onStateChanged(state: PoolState) {
    if (!state) return
    const {
      ended,
      started,
      filled,
      startDuration,
      endDuration,
      isTBASale,
      whitelistStartDuration,
      whitelistEndDuration,
      whitelistPublicDuration,
      kycEndDuration,
      isWhitelistStarted,
      isWhitelistEnd,
      isWhitelistPublic,
      isKycEnd,
    } = state
    if (isTBASale) {
      this.text = 'upcoming'
      return
    } else if (this.showOnlyState) {
      if (started && ended) {
        this.text = 'closed'
        this.warn = true
      } else if (started) {
        this.text = 'running'
        this.warn = false
      } else {
        this.text = 'upcoming'
        this.warn = false
      }
      return
    }
    if (ended) {
      this.text = filled ? 'Filled' : 'Ended'
      this.warn = !filled

      // this.warn = !filled
    } else if (!isWhitelistStarted) {
      this.text = `${formatDuration(whitelistStartDuration)} until whitelist opens`
    } else if (!isWhitelistEnd) {
      this.text = `${formatDuration(whitelistEndDuration)} until whitelist ends`
    } else if (!isWhitelistPublic) {
      this.text = `${formatDuration(whitelistPublicDuration)} until lottery results are public`
    } else if (!isKycEnd) {
      this.text = `${formatDuration(kycEndDuration)} until allocation announcement`
    } else if (started) {
      this.text = filled ? 'Filled' : `${formatDuration(endDuration)} until end`
    } else {
      this.text = `${formatDuration(startDuration)} until start`
    }
  }

  get backgroundClass() {
    return this.warn ? 'error-background' : 'success-background'
  }
  get circleClass() {
    return this.warn ? 'red1' : 'green'
  }
  get textClass() {
    return this.warn ? 'red1--text' : 'green--text'
  }
}
